import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  modal: {
    outline: 'none',
    maxWidth: '100%',
    width: '100%',
    margin: '0 auto',
    color: theme.palette.common.black,
    ['& .ReactModal__Overlay']: {
      position: 'absolute',
    },
    [`@media (min-width: ${BREAKPOINTS.mdd}px)`]: {
      maxWidth: 420,
    },
  },
  modalContainer: {
    position: 'relative',
    outline: 'none',
    paddingTop: 40,
    paddingBottom: 60,
    paddingRight: 20,
    paddingLeft: 20,
  },
  closeButton: {
    position: 'absolute',
    top: 18,
    right: 10,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    zIndex: 2,
    outline: 'none',
  },
  buttonStyles: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '0.75rem',
    marginTop: 40,
    '& > img': {
      filter: 'invert(1)',
    },
  },
  modalTitle: {
    marginBottom: 20,
    textAlign: 'center',
  },
  modalImage: {
    width: '100%',
    maxWidth: 484,
    maxHeight: 481,
  },
  maxHeight: {
    maxHeight: 'calc(100vh - 10px) !important',
  },
  link: {
    display: 'block',
    textAlign: 'center',
    marginTop: 20,
    color: '#111827',
    '&:hover': {
      color: 'black',
    },
  },
}))
