import { Auth } from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { isIOS, isMobileSafari } from 'react-device-detect'
import { I18nextProvider } from 'react-i18next'
import PullToRefresh from 'react-simple-pull-to-refresh'
import CookieBanner from '../components/cookieBanner'
import { title } from '../constants'
import awsConfig from './../aws-exports'
import CHRFooter from './../components/footer'
import CHRHeader from './../components/header'
import i18n from './../components/i18n'
import SEO from './../components/seo'
import { useStyles } from './styles'
// import { useAppState } from '../appState'
import { navigate } from 'gatsby'
import moment from 'moment'
import InstallChirpyestModal from '../components/joinModales/installChirpyest'
import useNavigateToExtStore from '../hooks/useNavigateToExtStore'

Amplify.configure(awsConfig)
Auth.configure(awsConfig)

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  openSignInModal: boolean
  isJoinPage?: boolean
  hasFooter?: boolean
  withBanner?: boolean
}

const deviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

const Layout = (props: DefaultLayoutProps) => {
  const { children, openSignInModal, isJoinPage } = props
  let { hasFooter = true, withBanner = false } = props

  hasFooter = true
  withBanner = true
  const navigateToStore = useNavigateToExtStore()[2]
  const [open, setOpen] = useState(false)
  const classes = useStyles({})
  let isPWAiOS =
    isIOS &&
    (navigator.standalone ||
      window.matchMedia('(display-mode: standalone)').matches)

  const handleRefresh = () => {
    window.location.reload()
  }

  const syncLogout = (event: StorageEvent): void => {
    if (event.key === 'logoutAllTabs' || event.key === 'loggedInRefresh') {
      window.location.reload()
    }
  }

  useEffect(() => {
    window.addEventListener('storage', syncLogout)
    return () => {
      window.removeEventListener('storage', syncLogout)
      window.localStorage.removeItem('logoutAllTabs')
      window.localStorage.removeItem('loggedInRefresh')
    }
  }, [])

  useEffect(() => {
    const installDialogLastShown = window.localStorage.getItem(
      'installDialogLastShown'
    )
    const canShowInstallDialog =
      !installDialogLastShown ||
      moment().diff(moment(installDialogLastShown), 'days') >= 30

    if (
      window.localStorage.getItem('addChirpyestModal') === 'true' ||
      canShowInstallDialog
    ) {
      setTimeout(() => {
        setOpen(true)
        window.localStorage.removeItem('addChirpyestModal')
      }, 1000 * 60)
    }
  }, [])

  useEffect(() => {
    const onboardingLastShown = localStorage.getItem('onboardingLastShown')
    const appInstalled = localStorage.getItem('appInstalled')
    if (
      isIOS &&
      isMobileSafari &&
      !appInstalled &&
      onboardingLastShown &&
      moment().diff(onboardingLastShown, 'days') > 30
    ) {
      navigate('/app/onboarding?howItWorks=true')
      localStorage.setItem('onboardingLastShown', new Date().toISOString())
    }
  }, [])

  const pageClasses = classNames({
    [classes.spaceBetween]: hasFooter,
    [classes.flexStart]: !hasFooter,
  })

  return (
    <React.Fragment>
      <SEO
        title={'Shop and Earn cash back'}
        description={
          'Shop 1000+ stores. Share style finds. Make money when you or friends buy from your personal boutique. Fashion, home, beauty, travel, lifestyle.'
        }
      />
      <PullToRefresh
        onRefresh={handleRefresh}
        isPullable={isPWAiOS && !withBanner}
      >
        <div className={pageClasses}>
          <CHRHeader
            openSignInModal={openSignInModal}
            isJoinPage={isJoinPage}
            isScrollable={!hasFooter}
            withBanner={withBanner}
          />
          <I18nextProvider i18n={i18n}>
            <main className={classes.mainContainer}>{children}</main>
          </I18nextProvider>
          {hasFooter && <CHRFooter title={title} />}
        </div>
        <CookieBanner />
        {/* <AddChirpyestModal
          openStatus={
            open &&
            deviceType() === 'mobile' &&
            location.pathname !== '/user/personal-info'
          }
          handleClose={() => setOpen(false)}
          onAddClick={() => {
            navigateToStore()
            setOpen(false)
          }}
        /> */}

        <InstallChirpyestModal
          openStatus={
            open &&
            (deviceType() === 'tablet' || deviceType() === 'mobile') &&
            isIOS
          }
          handleClose={() => setOpen(false)}
          onCtaBtnClick={() => {
            navigateToStore()
            setOpen(false)
          }}
        />
      </PullToRefresh>
    </React.Fragment>
  )
}

export default Layout
