import { Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import birdIcon from '../../../assets/images/chirpyest-logo.svg'
import closeMenuIcon from '../../../assets/images/close.svg'
import CHRButton from '../../button'
import { styles } from './styles'

interface InstallChirpyestModal {
  handleClose: any
  openStatus: boolean
  onCtaBtnClick: Function
  t: TFunction
}

const InstallChirpyestModal = ({
  handleClose,
  openStatus,
  onCtaBtnClick,
  t,
}: InstallChirpyestModal) => {
  const classes = styles()

  return (
    <>
      <Dialog
        open={openStatus}
        onClose={handleClose}
        aria-labelledby="addChirpyestModal"
        className={classes.modal}
        classes={{
          paper: classes.maxHeight,
        }}
      >
        <div className={classes.modalContainer}>
          <button onClick={handleClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
          <Typography variant="h2" className={classes.modalTitle}>
            chirpyest anywhere
          </Typography>
          <Typography variant="subtitle1" align="center">
            Experience Chirpiest from your pocket. Our mobile app brings all
            your favorite features to your fingertips.
          </Typography>
          <CHRButton
            onClick={onCtaBtnClick}
            label="download now"
            icon={birdIcon}
            customStyle={classes.buttonStyles}
          />
          <a href="#" className={classes.link} onClick={() => handleClose()}>
            continue to website
          </a>
        </div>
      </Dialog>
    </>
  )
}

export default withTranslation()(InstallChirpyestModal)
